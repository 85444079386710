import {
  AbortError,
  ApiError,
  BadRequestError,
  ConflictError,
  ForbiddenError,
  MethodNotAllowedError,
  NetworkError,
  NotFoundError,
  PaymentRequired,
  UnauthorizedError,
  UnprocessableEntityError,
} from "@/api/api-error";
import { QueryKey, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "@tanstack/vue-query";
import { Ref, ref } from "vue";

export type QueryOptions = {
  enabled?: boolean;
  retry?: boolean | number | ((failureCount: number, error: any) => boolean);
};

export type MutationOptions = {
  method?: "POST" | "PUT" | "DELETE";
  invalidateQueries?: QueryKey[];
};

export function useApi<T>(queryKey: any[], url: string, opts: QueryOptions = {}) {
  return useQuery<T>({
    queryKey: queryKey,
    queryFn: ({ signal }) => {
      return fetch(url, {
        signal,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
    },
    ...opts,
  });
}

export function useApiMutation<T>(url: string, opts: MutationOptions = {}) {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: T) => {
      return fetch(url, {
        method: opts.method || "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
    },
    onSuccess: () => {
      if (opts.invalidateQueries) {
        opts.invalidateQueries.forEach((queryKey) => {
          queryClient.invalidateQueries({ queryKey });
        });
      }
    },
  });
}

interface HasLength {
  length: number;
}

export function useApiInfinitely<T extends HasLength>(
  urlOrRef: string | Ref<string>,
  limit = 20,
  opts: QueryOptions = {}
) {
  const urlRef = ref(urlOrRef);
  return useInfiniteQuery<T>({
    queryKey: ["infinite", urlRef],
    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => {
      return lastPage.length === limit ? pages.length * limit : undefined;
    },
    queryFn: ({ pageParam = 0, signal }) => {
      const url = new URL(urlRef.value, window.location.origin);
      url.searchParams.append("offset", pageParam.toString());
      url.searchParams.append("limit", limit.toString());
      return fetch(url, {
        signal,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => handleResponse(response))
        .catch((error) => handleError(error));
    },
    ...opts,
  });
}

export async function handleResponse<T = any>(response: Response): Promise<T> {
  if (response.ok) {
    if (response.status === 204) {
      return {} as T;
    } else {
      return response.json();
    }
  } else if (response.status === 400) {
    throw new BadRequestError("Bad request.");
  } else if (response.status === 401) {
    throw new UnauthorizedError("Unauthorized.");
  } else if (response.status === 402) {
    throw new PaymentRequired("Payment required.");
  } else if (response.status === 403) {
    const payload = await response.json();
    throw new ForbiddenError("Forbidden.", payload.details);
  } else if (response.status === 405) {
    throw new MethodNotAllowedError("Method not allowed.");
  } else if (response.status === 404) {
    throw new NotFoundError("Not found.");
  } else if (response.status === 422) {
    const payload = await response.json();
    throw new UnprocessableEntityError(payload.error, payload.details);
  } else if (response.status === 409) {
    throw new ConflictError("Update conflict.");
  } else {
    throw new ApiError(response.statusText + " (" + response.status + ")");
  }
}

export function handleError(error): never {
  // Fetch raises TypeError on network errors
  if (error instanceof TypeError) {
    throw new NetworkError();
  } else if (error instanceof DOMException && error.name === "AbortError") {
    throw new AbortError();
  }
  throw error;
}
