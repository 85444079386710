<template>
  <modal class="light justify-center profile-preferences-modal" content-class="!mb-lg !px-lg">
    <form @submit.prevent="onSubmit">
      <modal-header :title="$t('profile.personal_preferences')"></modal-header>
      <modal-body>
        <div class="flex text-base text-text-primary mb-6 tracking-[0.5px]">
          {{ $t("profile.choose_your_preferences") }}
        </div>
        <form-field :label="$t('attributes.system_language')">
          <language-select
            v-model="language"
            :placeholder="$t('attributes.system_language')"
            :available-languages="languages"
            @input="setLocale"
          ></language-select>
        </form-field>
        <form-field :label="$t('attributes.content_languages')">
          <language-select
            v-model="content_languages"
            :placeholder="$t('attributes.content_languages')"
            :available-languages="contentLanguages"
            :multiselect="true"
          ></language-select>
        </form-field>
        <form-field :label="$t('color_theme.title')">
          <theme-select v-model="theme" @input="setTheme"></theme-select>
        </form-field>
      </modal-body>
      <modal-footer>
        <button class="btn-secondary" type="button" @click="onCancel">{{ $t("actions.skip") }}</button>
        <button class="btn-primary" type="submit" :disabled="$v.$invalid">{{ $t("actions.save") }}</button>
      </modal-footer>
    </form>
  </modal>
</template>

<script lang="ts">
import Vue from "vue";
import { Component } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import _ from "lodash";
import Users from "@/services/users";
import { getLocale, setLocale, availableLanguages, availableContentLanguages } from "@/i18n";
import { Theme, getActiveTheme, setTheme } from "@/theme";
import { updateProfile } from "@/actions";

@Component({})
export default class ProfilePreferencesModal extends Vue {
  language: string = getLocale();
  defaultLanguage: string = getLocale();

  content_languages: string[] = [getLocale()];
  default_content_languages = this.content_languages;

  theme: Theme = getActiveTheme();
  defaultTheme = getActiveTheme();

  @Validations()
  validations = {
    language: {},
    content_languages: {},
    theme: {},
  };

  get userService() {
    return new Users();
  }

  get languages(): {} {
    return availableLanguages;
  }

  get contentLanguages(): string[] {
    return Object.keys(availableContentLanguages);
  }

  setLocale(value: string) {
    setLocale(value);
  }

  setTheme(value: Theme) {
    setTheme(value);
  }

  async onSubmit() {
    await updateProfile(
      _.omitBy(
        {
          language: this.language,
          content_languages: this.content_languages,
          theme: this.theme,
        },
        _.isNil
      )
    );

    this.$emit("ok");
  }

  onCancel() {
    setLocale(this.defaultLanguage);
    setTheme(this.defaultTheme);
    this.$emit("cancel");
  }
}
</script>

<style lang="scss" scoped>
.profile-preferences-modal::v-deep {
  .content-wrapper {
    .content {
      width: 320px;
    }
  }
}
</style>
