<template>
  <page>
    <page-header :title="$t('ai_search.title')">
    </page-header>
    <page-content>
      <div class="flex flex-col gap-4 w-full max-w-2xl mx-auto">
        <!-- Specialty Area and New Chat button -->
        <div class="flex items-end gap-4">
          <div class="flex-1">
            <label for="specialtyArea" class="mb-2 font-semibold">Specialty Area</label>
            <select
              id="specialtyArea"
              v-model="specialtyArea"
              class="w-full p-2 rounded-lg border border-gray-200 bg-white"
            >
              <option value="" disabled>Select a specialty area</option>
              <option value="Orthopedics">Orthopedics</option>
              <option value="Dermatology">Dermatology</option>
              <option value="Radiology">Radiology</option>
            </select>
          </div>
          <button 
            class="btn-primary pl-4 flex items-center"
            @click="startNewChat"
          >
            <div>New Chat</div>
          </button>
        </div>

        <!-- Chat messages -->
        <div 
          ref="chatContainer"
          class="space-y-4"
        >
          <div v-for="(message, index) in chatHistory" :key="index" class="flex">
            <div :class="[
              'p-3 rounded-sm min-w-[200px] max-w-[75%]',
              message.type === 'user' ? 'bg-blue-100 text-blue-800 ml-auto' : 'bg-gray-100 text-gray-800'
            ]">
              <div class="font-bold mb-1">{{ message.type === 'user' ? 'Query:' : 'Response:' }}</div>
              <div class="whitespace-pre-wrap">{{ message.content }}</div>
            </div>
          </div>
        </div>

        <!-- Loading indicator -->
        <loader-big v-if="isLoading" class="my-4"></loader-big>

        <!-- Input field and Ask button -->
        <div class="flex items-center gap-2">
          <input
            type="text"
            class="flex-1 p-2 rounded-lg border border-gray-200 bg-white"
            :placeholder="$t('ai_search.placeholder')"
            v-model="query"
            @keyup.enter="onSearch"
            :disabled="isLoading"
          />
          <button 
            class="btn-primary px-4 py-2"
            @click="onSearch"
            :disabled="isLoading || !query.trim()"
          >
            Ask
          </button>
        </div>
      </div>
    </page-content>
  </page>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import AiSearchService from "@/services/ai-search-service";

interface ChatMessage {
  type: "user" | "assistant";
  content: string;
}

@Component({})
export default class AiSearchPage extends Vue {
  query = "";
  specialtyArea = "Orthopedics";
  chatHistory: ChatMessage[] = [];
  aiSearchService = new AiSearchService();
  isLoading = false;

  async onSearch() {
    if (!this.query.trim() || this.isLoading) return;

    this.chatHistory.push({ type: "user", content: this.query });
    this.isLoading = true;

    try {
      const chatHistoryForApi = this.chatHistory.map((message) => ({
        role: message.type,
        content: message.content,
      }));

      const response = await this.aiSearchService.specialtySearch({
        specialty_area: this.specialtyArea,
        chat_history: chatHistoryForApi,
      });

      this.chatHistory.push({ type: "assistant", content: response.result });
    } catch (error) {
      console.error("Error fetching AI search results:", error);
      this.chatHistory.push({ type: "assistant", content: "An error occurred while fetching results." });
    } finally {
      this.isLoading = false;
      this.query = "";
    }
  }

  startNewChat() {
    this.chatHistory = [];
    this.query = "";
  }
}
</script>

