<template>
  <div class="scroll-detector">
    <slot></slot>
  </div>
</template>

<script lang="ts">
import _ from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ScrollDetector extends Vue {
  @Prop({ default: 200 })
  threshold: number;

  lastScrollTop: number = 0;

  created() {
    console.log('ScrollDetector created');
  }

  mounted() {
    console.log('ScrollDetector mounted');
    this.initializeObserver();
  }

  initializeObserver() {
    console.log('Initializing IntersectionObserver');
    const options = {
      root: null,
      rootMargin: `${this.threshold}px`,
      threshold: 0
    };

    const observer = new IntersectionObserver((entries) => {
      console.log('IntersectionObserver callback triggered');
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log('Element is intersecting');
          this.emitEnd();
        }
      });
    }, options);

    observer.observe(this.$el);
    console.log('Observer started observing element');
  }

  activated() {
    console.log('ScrollDetector activated');
    window.addEventListener("scroll", this.onScroll);
  }

  deactivated() {
    console.log('ScrollDetector deactivated');
    window.removeEventListener("scroll", this.onScroll);
  }

  beforeDestroy() {
    console.log('ScrollDetector beforeDestroy');
  }

  destroyed() {
    console.log('ScrollDetector destroyed');
    window.removeEventListener("scroll", this.onScroll);
  }

  onScroll = () => {
    const scrollTop = window.scrollY;

    // Check whether we have scrolled below the last max
    if (scrollTop > this.lastScrollTop) {
      // Calculate the distance between bottom of visible content in viewport and end of this element
      const bottomDistance = this.calculateBottomDistance(scrollTop);

      // Emit scroll end if distance to end is below threshold
      if (bottomDistance < this.threshold) {
        this.emitEnd();
      }
    }

    this.lastScrollTop = scrollTop;
  }

  calculateBottomDistance(scrollTop) {
    const element = this.$el;
    const rect = element.getBoundingClientRect();
    const contentTop = rect.top + scrollTop;
    const contentBottom = contentTop + element.scrollHeight;
    const scrollBottom = scrollTop + window.innerHeight;

    return contentBottom - scrollBottom;
  }

  // Debounce the event so that it is emitted only once when it comes to end of content
  emitEnd = _.debounce(() => this.$emit("end"), 300, { leading: true, trailing: false });
}
</script>
