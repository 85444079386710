export class ApiError extends Error {
  details: Record<string, string> = null;
  constructor(message = null, details = null) {
    super(message);
    this.details = details;
  }
}
export class BadRequestError extends ApiError {}
export class UnauthorizedError extends ApiError {}
export class ConflictError extends ApiError {}
export class PaymentRequired extends ApiError {}
export class ForbiddenError extends ApiError {}
export class NotFoundError extends ApiError {}
export class MethodNotAllowedError extends ApiError {}
export class UnprocessableEntityError extends ApiError {}
export class NetworkError extends ApiError {}
export class AbortError extends ApiError {}
